.rating-holder {
    display: flex;
}
.rating-bar {
    display: flex;
    width: 17rem;
}
.rating-icon {
    align-items: center;
    display: flex;
    color: #43A2DB;
    cursor: pointer;
    font-size: 1.2rem;
    justify-content: center;
    padding: 0;
    transition: all 0.5s ease-in-out;
    margin-right: 2rem;
}
.rotate {
    transform: rotate(144deg);
}