.card .card-header .btn-link.collapsed:before {
    content: "\0025BC";
    font-weight: bold;
    color: white;
    float:left;
    padding-right: 2em;
}

.card .card-header .btn-link:before {
    content: "\0025B2";
    font-weight: bold;
    color: white;
    float:left;
    padding-right: 2em;
}

.card .card-header .btn-link.collapsed:after {
    content: "\0025BC";
    font-weight: bold;
    color: white;
    float:right;
}

.card .card-header .btn-link:after {
    content: "\0025B2";
    font-weight: bold;
    color: white;
    float:right;
}